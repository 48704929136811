






import Vue from 'vue'
import ShippingRatesPage from './ShippingRates.vue'
export default Vue.extend({
  components: { ShippingRatesPage },

    
})
